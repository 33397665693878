.pageLoading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width: 95vw; */
    height: 90vh;
}

.pageLoading-container>img {
    height: 40px;
    margin-bottom: 75px;
}