.loading_container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    width: 100%;
    height: 75vh;
    color: #1D7A78;
    font-size: 60px;
}