body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  max-width: 100vw;
}

.ant-btn-primary {
  background-color: #1D7A78;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #1D7A78;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
  background-color: rgb(20, 83, 82);
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
  border-color: #1D7A78;
  color: #1D7A78;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #1D7A78 !important;
  border-color: #1D7A78 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  padding: 15px;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 94px);
}

.page::-webkit-scrollbar-track {
  border-radius: 0.125rem;
  background-color: rgb(221, 220, 220);
}

.page::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 0.125rem;
}

.page::-webkit-scrollbar-thumb {
  border-radius: 0.125rem;
  background-color: gray;
}

.loading-spin {
  animation: spin 0.9s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.delete-icon,
.update-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  font-size: 15px;
  /* padding: 5px 10px; */
  width: fit-content;
  height: fit-content;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
}

.delete-icon {
  background-color: transparent;
  border: #CD252C 1px solid;
  color: #CD252C;
}

.delete-icon:hover {
  background-color: #CD252C;
  color: #ffffff;
}

.update-icon {
  border: #1D7A78 solid 1px;
  color: #1D7A78;
}

.update-icon:hover {
  background-color: #196a69;
  color: #ffffff;
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: #CD252C;
}

.ant-btn-primary.ant-btn-dangerous:hover {
  background-color: #f51c23;
}

.pagination-button {
  cursor: pointer;
  text-align: center;
  width: 150px;
  padding: 5px 10px;
  border-radius: 7px;
  background-color: rgb(8, 8, 224);
  color: white;
  /* border: rgb(8, 8, 224) 1px solid; */
  margin: 20px auto 0px;
  transition: 0.4s;
}

.pagination-button:hover {
  background-color: rgb(55, 55, 238);
}

.add-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #1D7A78;
  cursor: pointer;
  color: #ffffff;
  padding: 12px 15px 12px 12px;
  border-radius: 8px;
  transition: 0.4s;
}

.add-button:hover {
  background-color: #1a6d6c;
}

.page-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active-row {
  border: 10px gray solid;
  background-color: rgb(238, 236, 236);
  border-radius: 5px;
}

.ant-pagination .ant-pagination-options {
  display: none;
}

.add-column {
  display: flex;
  align-items: center;
  padding: 5px 0;
  min-height: 40px;
}

.add-textarea {
  display: flex;
  align-items: center;
  padding: 5px 0;
  min-height: 120px;
}

.banner-add-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-weight: 450;
}

.add-right {
  min-width: 370px;
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody>tr>td {
  word-wrap: break-word;
  word-break: break-all;
}

.content-name{
  margin-top: 25px;
  margin-bottom: 10px;
}